.location .box {
  position: relative;
}

.image-overlay-container {
  position: relative;
}

.image-overlay-container .image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.image-overlay-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay arka plan rengi ve opaklığı */
  border-radius: 5px;
  color: #fff; /* Yazı rengi */
}

.image-overlay-container .overlay h5 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.image-overlay-container .overlay label {
  opacity: 0.8;
  margin-right: 10px;
}
