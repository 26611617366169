header {
  background-color: #1D2636;
  height: 12vh;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  padding: 15px 0;
  position: sticky;
  top: 0;
  z-index: 99999;
  color: white;
}
header img {
  width: 170px;
}
header a {
  margin-left: 30px;
  transition: 0.5s;
  font-weight: 500;
}
header a:hover {
  color: white;
}
header span {
  width: auto;
  padding: 0px 6px;
  background: #F1592A;
  border-radius: 50px;
  color: #ffffff;
  margin-right: 4px;
  font-weight: 400;
}
header h4 {
  font-weight: 500;
  margin-right: 20px;
}
header .toggle {
  display: none;
}
.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

 .dropdown-content {
  /* display: none; */
  position: absolute;
  right: 0;
  background-color: white;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 5px;
  top: 30px;
  border-radius: 4px;
  line-height: 50px;
}

 .dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
  margin-left: 0;
  padding-left: 10px;
}

 .dropdown-content a:hover {
  background-color:#F1592A;
  color: white;
}

/* nav .nav_middle .dropdown:hover .dropdown-content {
  display: block;
} */

 .dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
.li_dropdown {
  position: relative;
  left: 20px;
}
.btncall {
  position:fixed; 
  right: 40px;
  top:590px;
}
.btnwp {
  position:fixed; 
  right: 40px;
  top:540px;
}
@media screen and (max-width: 1370px) {
  header {
    padding: 0;
  }
  header img {
    margin-top: 20px;
  }
  /* header .btncall {
    position: fixed;
    top: 400px;
  }
  header .btnwp {
    position: fixed;
    top: 450px;
  } */
  header .nav .flex {
    display: none;
  }
  header .toggle {
    display: block;
    margin-top: 20px;
  }
  header .small {
    position: absolute;
    top: 10vh;
    left: 0;
    width: 100%;
    background-color: #F1592A;
    padding: 20px;
  }
  header li a {
    display: inline-block;
    margin-bottom: 20px;
    color: #fff;
  }
  .dropdown-content { 
    left:30px;
    max-height: 400px;
    overflow: scroll;
  }
  .btn1 {
    border: 1px solid white;
    margin-bottom: 5px;
  }


}
