.item-card-container {
  width: 60%;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  padding: 10px;
}

.item-card {
  display: flex;
  align-items: center;
  gap: 20px;
}

.item-image {
  width: 150px; /* Resmi büyütmek için genişliği artırdık */
  height: 150px; /* Yüksekliği de aynı oranda artırdık */
  object-fit: cover; /* Resmi düzgün orantılı şekilde keser */
}

.item-info h1 {
  font-weight: bold;
}

.item-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.quantity-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.quantity-button {
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #d0d0d0;
  cursor: pointer;
}

.delete-icon {
  color: #ff0000;
  cursor: pointer;
}

.item-total {
  font-weight: bold;
  font-size: 18px;
  margin-left: auto;
}

@media screen and (max-width: 800px) {
 .item-card {
  display: block;
 }
}
  