.market {
    /* Add any necessary styling for the market section */
  }
  
  .containered {
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Adjust the spacing between rows */
  }
  
  .row {
    display: flex;
    gap: 1rem; /* Adjust the spacing between images */
    flex-wrap: wrap; /* Allows wrapping if the container width is not enough */
  }
  
  .photo-item {
    flex: 1 1 calc(25% - 1rem); /* Each item takes up 25% of the container width minus the gap */
    box-sizing: border-box;
  }
  
  .photo-item img {
    width: 100%;
    height: auto;
    display: block;
  }
  .software-container {
    display: flex;
    justify-content: space-between;
  }
  .leftSide {
    width: 50%;
  }
  .rightSide {
    width: 40%;
  }

  @media screen and (max-width: 800px) {
    .software-container {
     display: block;
    }
    .leftSide {
      width: 90%;
    }
    .rightSide {
      width: 90%;
    }
   }
  